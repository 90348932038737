import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./terms.css";
import NewLayout from "../layout/newLayout";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <NewLayout>
      <div className="terms-pages">
        <div className="container">
          <h2>{t("privacy.heading")}</h2>
          <div className="terms-grid">
            <p>{t("privacy.subHeading")}</p>
            <h3>{t("privacy.step1heading")}</h3>
            <p>{t("privacy.list1")}</p>
            <p>{t("privacy.list2")}</p>
            <p>
              {t("privacy.list3")}{" "}
              <Link to="https://hidoc.co.il/">{t("privacy.url1")}</Link>
            </p>
            <p>{t("privacy.list4")}</p>
            <h3>{t("privacy.step2heading")}</h3>
            <ol>
              <li>{t("privacy.list5")}</li>
              <li>{t("privacy.list6")}</li>
              <li>{t("privacy.list7")}</li>
              <li>{t("privacy.list8")}</li>
              <li>{t("privacy.list9")}</li>
            </ol>
            <p>{t("privacy.list62")}</p>
            <p>{t("privacy.list63")}</p>
            <h3>{t("privacy.step3heading")}</h3>
            <p>{t("privacy.list10")}</p>
            <h3>{t("privacy.step4heading")}</h3>
            <p>{t("privacy.list11")}</p>
            <p>{t("privacy.list12")}</p>
            <ol>
              <li>{t("privacy.list13")}</li>
              <li>{t("privacy.list14")}</li>
              <li>{t("privacy.list15")}</li>
              <li>{t("privacy.list16")}</li>
              <li> {t("privacy.list17")}.</li>
              <li>{t("privacy.list18")}</li>
              <li> {t("privacy.list19")}</li>
              <li> {t("privacy.list20")}</li>
              <li> {t("privacy.list21")}</li>
              <li>{t("privacy.list22")}</li>
            </ol>
            <h3>{t("privacy.step5heading")}</h3>
            <p>{t("privacy.list23")}</p>
            <p>{t("privacy.list24")}</p>
            <p>{t("privacy.list25")}</p>
            <p>{t("privacy.list26")}</p>
            <p>{t("privacy.list27")}</p>
            <p>{t("privacy.list28")}</p>
            <h3>{t("privacy.step6heading")}</h3>
            <ol>
              <li> {t("privacy.list29")} </li>
              <li>{t("privacy.list30")}</li>
              <li>{t("privacy.list31")} </li>
              <li>{t("privacy.list32")}</li>
            </ol>
            <h3>{t("privacy.step7heading")}</h3>
            <p>{t("privacy.list33")}</p>
            <h3>{t("privacy.step8heading")}</h3>
            <p>{t("privacy.list35")}</p>
            <ol>
              <li>{t("privacy.list35")}</li>
              <li>{t("privacy.list37")}</li>
              <li>{t("privacy.list38")}</li>
              <li>{t("privacy.list39")}</li>
              <li>{t("privacy.list40")}</li>
              <li>{t("privacy.list41")}</li>
              <li>{t("privacy.list42")}</li>
              <li>{t("privacy.list43")}</li>
              <li>{t("privacy.list44")}</li>
            </ol>
            <h3>{t("privacy.step9heading")}</h3>
            <p>{t("privacy.list45")}</p>
            <p>{t("privacy.list46")}</p>
            <p>{t("privacy.list47")}</p>
            <p>
              {t("privacy.list48")}{" "}
              <Link to="/contact">{t("privacy.list49")}</Link>{" "}
              {t("privacy.url2")}
            </p>
            <p>{t("privacy.list50")}</p>
            {/* <h3>{t("privacy.step10heading")}</h3>
            <p>{t("privacy.list51")}</p> */}
            <h3>{t("privacy.step11heading")}</h3>
            <p>{t("privacy.list52")}</p>
            <p>{t("privacy.list53")}</p>
            <h3>{t("privacy.step12heading")}</h3>
            <p>{t("privacy.list54")}</p>
            <p> {t("privacy.list55")}</p>
            <p>{t("privacy.list56")}</p>
            <p>{t("privacy.list57")}</p>
            <p>{t("privacy.list58")}</p>
            <h3>{t("privacy.step13heading")}</h3>
            <p>{t("privacy.list59")}</p>
            <p>{t("privacy.list60")}</p>
            <h3>{t("privacy.step14heading")}</h3>
            <p>
              {t("privacy.list61")}{" "}
              <Link to="/contact"> {t("privacy.url3")}</Link>{" "}
            </p>
          </div>
        </div>
      </div>
    </NewLayout>
  );
};

export default PrivacyPolicy;
