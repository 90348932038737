import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./terms.css";
import NewLayout from "../layout/newLayout";

const TermsConditions = () => {
  const { t } = useTranslation();
  return (
    <NewLayout>
      <div className="terms-pages">
        <div className="container">
          <h2>{t("terms.heading")}</h2>
          <div className="terms-grid">
            <p>{t("terms.subHeading")}</p>
            <p>{t("terms.list1")}</p>
            <p>{t("terms.list2")}</p>
            <p>
              {t("terms.list3")}
              <Link to="/privacy"> {t("terms.urlPrivacy")} </Link>
              {t("terms.list4")}
              <Link to="/privacy"> {t("terms.urlPrivacy")} </Link>
              {t("terms.list5")}
            </p>
            <p>{t("terms.list7")}</p>
            <p>
              {t("terms.list8")}
              <Link to="/contact">{t("terms.url2")}</Link>.
            </p>
            <h3>{t("terms.step1heading")}</h3>
            <p dangerouslySetInnerHTML={{ __html: t("terms.list9") }} />
            <p>
              <p dangerouslySetInnerHTML={{ __html: t("terms.list10") }} />
              <Link to="/"> {t("terms.urlsite")} </Link>
              {t("terms.list11")}
            </p>
            <p dangerouslySetInnerHTML={{ __html: t("terms.list12") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.list13") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.list14") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.list15") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.list16") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.list17") }} />
            <p>
              <p
                dangerouslySetInnerHTML={{
                  __html: [t("terms.list18"), t("terms.list19")],
                }}
              />
            </p>
            <p dangerouslySetInnerHTML={{ __html: t("terms.list20") }} />
            <h3>{t("terms.step2heading")}</h3>
            <p>{t("terms.list21")}</p>
            <p>{t("terms.list22")}</p>
            <p>{t("terms.list23")}</p>
            <p>{t("terms.list24")}</p>
            <p>{t("terms.list25")}</p>
            <p>{t("terms.list26")}</p>
            <p>{t("terms.list27")}</p>
            <h3>{t("terms.step3heading")}</h3>
            <p>{t("terms.list28")}</p>
            <p>{t("terms.list29")}</p>
            <p>{t("terms.list30")}</p>
            <h3>{t("terms.step4heading")}</h3>
            <p>{t("terms.list31")}</p>
            <p>{t("terms.list32")}</p>
            <p>{t("terms.list33")}</p>
            <p>{t("terms.list34")}</p>
            <p>{t("terms.list35")}</p>
            <p>{t("terms.list36")}</p>
            <p>{t("terms.list37")}</p>
            <h3>{t("terms.step5heading")}</h3>
            <p>{t("terms.list38")}</p>
            <p>{t("terms.list39")}</p>
            <p>{t("terms.list40")}</p>
            <p>{t("terms.list41")}</p>
            <p>{t("terms.list42")}</p>
            <p>{t("terms.list43")}</p>
            <p>{t("terms.list44")}</p>
            <p>{t("terms.list45")}</p>
            <p>{t("terms.list46")}</p>
            <p>{t("terms.list47")}</p> <p>{t("terms.list48")}</p>
            <p>{t("terms.list49")}</p>
            <p>{t("terms.list50")}</p>
            <p>{t("terms.list51")}</p>
            <p>{t("terms.list52")}</p>
            <h3>{t("terms.step6heading")}</h3>
            <p>{t("terms.list53")}</p>
            <p>{t("terms.list54")}</p>
            <p>
              {t("terms.list55")}
              <b>{t("terms.list111")} </b>{" "}
              <Link to="https://www.hidoc.co.il/medaat">{t("terms.url3")}</Link>
            </p>
            <p>{t("terms.list56")}</p>
            <p>{t("terms.list57")}</p>
            <p>{t("terms.list58")}</p>
            <p>{t("terms.list59")}</p>
            <p>{t("terms.list60")}</p>
            <p>{t("terms.list61")}</p>
            <h3>{t("terms.step7heading")}</h3>
            <p>{t("terms.list62")}</p>
            <p>{t("terms.list63")}</p>
            <p>{t("terms.list64")}</p>
            <p>{t("terms.list110")}</p>
            <p>{t("terms.list65")}</p>
            <p>{t("terms.list66")}</p>
            <h3>{t("terms.step8heading")}</h3>
            <p>{t("terms.list67")}</p>
            <p>{t("terms.list68")}</p>
            <p>{t("terms.list69")}</p>
            <p>{t("terms.list70")}</p>
            <p>{t("terms.list71")}</p>
            <h3>{t("terms.step9heading")}</h3>
            <p>
              {t("terms.list72")}{" "}
              <Link to="/privacy">{t("terms.urlPrivacy")}</Link>{" "}
              {t("terms.list73")}
            </p>
            <p>{t("terms.list74")}</p>
            <p>{t("terms.list75")}</p>
            <p>{t("terms.list76")}</p>
            <p>{t("terms.list77")}</p>
            <p>{t("terms.list78")}</p>
            <p>{t("terms.list79")}</p>
            <h3>{t("terms.step10heading")}</h3>
            <p>{t("terms.list80")}</p>
            <p>{t("terms.list81")}</p>
            <p>{t("terms.list82")}</p>
            <h3>{t("terms.step11heading")}</h3>
            <p>{t("terms.list83")}</p>
            <p>{t("terms.list84")}</p>
            <p>{t("terms.list85")}</p>
            <p>{t("terms.list86")}</p>
            <p>{t("terms.list87")}</p>
            <p>{t("terms.list88")}</p>
            <p>{t("terms.list89")}</p>
            <p>{t("terms.list90")}</p>
            <p>{t("terms.list91")}</p>
            <h3>{t("terms.step12heading")}</h3>
            <p>{t("terms.list92")}</p>
            <p>{t("terms.list93")}</p>
            <p>{t("terms.list94")}</p>
            <p>{t("terms.list95")}</p>
            <p>{t("terms.list96")}</p>
            <h3>{t("terms.step13heading")}</h3>
            <p>{t("terms.list97")}</p>
            <p>{t("terms.list98")}</p>
            <p>{t("terms.list99")}</p>
            <h3>{t("terms.step14heading")}</h3>
            <p>{t("terms.list100")}</p>
            <p>{t("terms.list101")}</p>
            <p>{t("terms.list102")}</p>
            <p>{t("terms.list103")}</p>
            <p>{t("terms.list104")}</p>
            <p>{t("terms.list105")}</p>
            <p>{t("terms.list106")}</p>
            <p>{t("terms.list107")}</p>
            <p>{t("terms.list108")}</p>
            <p>
              <i> {t("terms.list109")}</i>
            </p>
          </div>
        </div>
      </div>
    </NewLayout>
  );
};

export default TermsConditions;
