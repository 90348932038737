import Accordion from "react-bootstrap/Accordion";

const FaqList = () => {
  const generalFaq = [
    {
      id: 1,
      ques: "איך אדע אם הבעיה שלי מתאימה לייעוץ אונליין?",
      ans: "כחלק מתהליך שליחת הבקשה לייעוץ דרך המערכת, סיבת הפניה נשלחת לכלל הרופאים שלנו בתחום הרלוונטי לפניה. במידה וסיבת הפניה אינה מתאימה לייעוץ אונליין הרופאים שלנו דוחים את הבקשה ואנו נעדכן אתכם בהקדם.",
    },
    {
      id: 2,
      ques: "איך רופא יכול לייעץ מרחוק מבלי מפגש פרונטלי?",
      ans: "יש הרבה נושאים רפואיים בהם אין צורך בפגישה פרונטלית, לפי הספרות לפחות ב-70% מהמקרים ניתן לייעץ מרחוק. הייעוץ יכול להתבסס על תוצאות בדיקות קודמות/ קבצי דימות/ סיכומי טיפול והמלצות קודמות, ואפילו על סמך תמונה של הבעיה הרפואית (אם נראית לעין). המערכת שלנו יודעת לקבל את כל סוגי הקבצים ואני נבקש ממכם אותם לאחר השלמת תהליך הפגישה. את המסמכים הרופא מקבל טרם הפגישה, עובר עליהם ומגיע מוכן לפגישת הייעוץ שתואמה.",
    },
    {
      id: 3,
      ques: "האם השירות של היידוק מכוסה על ידי ביטוחי הבריאות?",
      ans: "כן! בעלי פוליסת ביטוח בריאות הכוללת סעיף אמבולטורי שמכסה ייעוצים עם רופאים מומחים, זכאים להחזר עבור הייעוץ בהיידוק (בכפוף לתנאי הפוליסה). בסיום הייעוץ ניתן להגיש לחברת הביטוח שלכם את החשבונית וסיכום הטיפול לתביעה עבור ההחזר.",
    },
    {
      id: 4,
      ques: "האם ניתן להשתמש בהיידוק מחוץ לישראל?",
      ans: "בהחלט! ניתן לשלוח בקשה לשיחת ייעוץ עם רופא ישראלי מומחה מכל מקום ובכל זמן! שיחת הייעוץ מתקיימת בזום וסיכום הטיפול נשלח אליכם להמשך טיפול בכל מקום שאליו תפנו.",
    },
    {
      id: 5,
      ques: "איך היידוק מגינים על הפרטיות שלי?",
      ans: 'אנו משקיעים את מירב המאמצים על מנת לשמור על פרטיות הלקוחות שלנו ואבטחת המידע שלהם. אנו עומדים בתקני ISO של אבטחת מידע (27799 ו 27701). מידע נוסף בנושא ניתן לקרוא במדיניות הפרטיות שלנו - <a href="/privacy">https://hidoc.co.il/privacy</a>.',
    },
    {
      id: 6,
      ques: "האם מערכת התשלומים מאובטחת?",
      ans: "הסליקה באתר נעשית באמצעות מערכת Tranzila אשר עומדת בתקן אבטחה המחמיר ביותר - PCI DSS Level-1 וההתממשקות אליהם נעשית באופן מוצפן בלבד.",
    },
    {
      id: 7,
      ques: "האם יש החזר מחברות הביטוח?",
      ans: "אנחנו מוכרים בכל חברות הביטוח כייעוץ פרטי אונליין. ניתן להגיש את סיכום הטיפול ואת החשבונית לחברת הביטוח ולקבל החזר בהתאם לתנאי הפוליסה.",
    },
    {
      id: 8,
      ques: "האם אפשר ליצור קשר עם הרופא לתוצאות בדיקות והמשך טיפול?",
      ans: "במידה והרופא הפנה אותך לבדיקות וטיפול המשך, ניתן דרך המערכת לתאם ייעוץ המשך בעלות מופחתת של 390 ש״ח (האפשרות תופיע בעמוד ״תורים שעברו״)",
    },
    {
      id: 9,
      ques: "אם אצטרך המשך טיפול מה העלות?",
      ans: "שיחת ייעוץ חוזרת במערכת היא בעלות מופחתת של 390 ש״ח. לאחר השיחה הראשונה ניתן לתאם ייעוץ נוסף ישירות לאותו הרופא.",
    },
    {
      id: 10,
      ques: "האם סיכום הטיפול מתקבל בקופות החולים?",
      ans: 'הייעוץ הוא פרטי ולכן סיכום הטיפול הינו סיכום פרטי אשר חתום בחתימה דיגיטלית ע"י הרופא המייעץ. ניתן לגשת עם הסיכום לרופא המשפחה בקופה ולהמיר את ההמלצות והמרשם למסמך של הקופה.',
    },
    {
      id: 11,
      ques: "איך אני קונה את התרופות בסופר פארם",
      ans: "אנחנו עובדים בשיתוף פעולה עם בתי המרקחת של רשת סופר פארם. המרשם הינו מרשם פרטי (לא במימון הקופה) ונשלח אוטומטית לכל בתי המרקחת ברשת סופר פארם. ניתן לגשת ולרכוש את התרופות בהצגת ת.ז לרוקח.",
    },
    {
      id: 12,
      ques: "אם אני רוצה סבסוד של קופת החולים לתרופות",
      ans: 'המרשם אשר מופק ממרכת היידוק חתום בחתימה דגיטלית ע"י הרופא המייעץ ונשלח באופן אוטומטי לבתי המרקחת של רשת סופר פארם כמרשם פרטי, ניתן לשלוח את המרשם הדיגיטלי לרופא המשפחה בקופה ולהמיר את המרשם וההמלצות למרשם מסובסד של הקופה.',
    },
    {
      id: 13,
      ques: "הרופאים שלכם טובים?",
      ans: "הרופאים שלנו מדהימים, בכירים ואיכותיים! כל הרופאים בהיידוק הינם רופאים מומחים המוכרים עם רשיון במשרד הבריאות. אנו בוחרים בקפידה את המומחים שלנו.",
    },
    {
      id: 14,
      ques: "אם ארצה להגיע לאותו הרופא פרטי אחרי פגישה אצלכם?",
      ans: "פגישת הייעוץ בהיידוק יכולה להיחשב כלחיצת יד ראשונית עם הרופא המייעץ. לאחר פגישה זו במידה ויש צורך או רצון להגיע לפגישה פרונטלית ניתן לפנות למרפאה הפרטית של אותו רופא מייעץ. וכך לחסוך את הפגישה הראשונה בה הרוופא מפנה לבדיקות וכו",
    },
    {
      id: 15,
      ques: "אם אני משלמת בעבור קרוב/ת משפחה,הבן שלי החשבונית והסיכום יצאויוצאת על שמו/ה?",
      ans: "בתהליך שליחת הבקשה במערכת, יש שדות המיועדים להזנת פרטי המטופל, במידה והוא אינו אותו אדם שהזמין את הייעוץ.",
    },
  ];
  // const privacyFaq = [
  //   {
  //     id: 1,
  //     ques: t("faq.que8"),
  //     ans: [t("faq.ans8.1"), t("faq.ans8.2"), t("faq.ans8.3")],
  //   },
  //   {
  //     id: 2,
  //     ques: t("faq.que9"),
  //     ans: [t("faq.ans9.1"), t("faq.ans9.2")],
  //   },
  // ];
  // const paymentFaq = [
  //   {
  //     id: 1,
  //     ques: t("faq.que10"),
  //     ans: [t("faq.ans10.1")],
  //   },
  //   {
  //     id: 2,
  //     ques: t("faq.que11"),
  //     ans: [t("faq.ans11.1")],
  //   },
  // ];
  // const technicalFaq = [
  //   {
  //     id: 1,
  //     ques: t("faq.que12"),
  //     ans: [t("faq.ans12.1")],
  //   },
  //   {
  //     id: 2,
  //     ques: t("faq.que13"),
  //     ans: [t("faq.ans13.1")],
  //   },
  //   {
  //     id: 3,
  //     ques: t("faq.que14"),
  //     ans: [t("faq.ans14.1")],
  //   },
  //   {
  //     id: 4,
  //     ques: t("faq.que15"),
  //     ans: [t("faq.ans15.1")],
  //   },
  // ];
  return (
    <div className="new-faq-list-section">
      <div className="new-faq-list">
        {/* <h6> {t("faq.generalHeading")} </h6> */}
        <Accordion defaultActiveKey="0">
          {generalFaq.map((e, i) => (
            <Accordion.Item
              eventKey={`${i}`}
              key={i}
              className="first-accordian"
            >
              <Accordion.Header>{e?.ques}</Accordion.Header>
              <Accordion.Body>
                <p dangerouslySetInnerHTML={{ __html: e?.ans }} />
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>

      {/* <div className="new-faq-list">
        <h6> {t("faq.privacyHeading")} </h6>
        <Accordion>
          {privacyFaq.map((e, i) => (
            <Accordion.Item eventKey={`${i}`} key={i}>
              <Accordion.Header>{e?.ques}</Accordion.Header>
              <Accordion.Body>
                {e.ans.length > 0 &&
                  e.ans.map((el, i) => (
                    <p dangerouslySetInnerHTML={{ __html: el }} />
                  ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div> */}

      {/* <div className="new-faq-list">
        <h6> {t("faq.paymentHeading")} </h6>
        <Accordion>
          {paymentFaq.map((e, i) => (
            <Accordion.Item eventKey={`${i}`} key={i}>
              <Accordion.Header>{e?.ques}</Accordion.Header>
              <Accordion.Body>
                {e.ans.length > 0 &&
                  e.ans.map((el, i) => (
                    <p dangerouslySetInnerHTML={{ __html: el }} />
                  ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div> */}
      {/* <div className="new-faq-list">
        <h6> {t("faq.technicalHeading")} </h6>
        <Accordion>
          {technicalFaq.map((e, i) => (
            <Accordion.Item eventKey={`${i}`} key={i}>
              <Accordion.Header>{e?.ques}</Accordion.Header>
              <Accordion.Body>
                {e.ans.length > 0 &&
                  e.ans.map((el, i) => (
                    <p dangerouslySetInnerHTML={{ __html: el }} />
                  ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div> */}
    </div>
  );
};

export default FaqList;
