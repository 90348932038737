import "./doctor.css";
import NewLayout from "../layout/newLayout";
import DoctorBanner from "./banner";
import DoctorProfile from "./profile";
import DoctorTestimonial from "./doctorTestimonial";
import RelatedDoctor from "./relatedDoctor";

const DoctorDetail = () => {
  return (
    <NewLayout>
      <div className="doctor-page">
        <DoctorBanner />
        <DoctorProfile />
        <DoctorTestimonial />
        <RelatedDoctor />
      </div>
    </NewLayout>
  );
};

export default DoctorDetail;
