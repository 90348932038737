import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";
import "./yarpa.css";
import NewLayout from "../layout/newLayout";
import PharmaciesListData from "../../components/yarpa/pharmaciesList.json";
import search from "../../assets/images/new/search.svg";
import searchIconSm from "../../assets/images/new/search-icon-sm.svg";

const PharmaciesList = () => {
  const { t } = useTranslation();
  const PharmaciesListArray = PharmaciesListData.pharmacieslist;
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setFilteredData(PharmaciesListArray);
  }, [PharmaciesListArray]);

  const handleSearch = (e) => {
    const searchWord = e.target.value;
    setSearchText(searchWord);

    if (searchWord === "") {
      setFilteredData(PharmaciesListArray);
    } else {
      const newFilter = PharmaciesListArray?.filter((pharmacy) =>
        pharmacy?.toLowerCase().includes(searchWord.toLowerCase())
      );
      setFilteredData(newFilter);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <NewLayout>
      <div className="pharmacies-list-page">
        <div className="container">
          <div className="pharmacies-list-title">
            <h2>{t("Yarpa pharmacies")}</h2>
          </div>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="search-input-form">
              <Form.Control
                type="search"
                placeholder={t("Pharmacies...")}
                value={searchText}
                onChange={handleSearch}
                required
              />
              <img
                src={search}
                alt="search"
                className="search-icon"
                width={24}
                height={24}
              />
              <Button className="black-btn" type="submit">
                <img src={searchIconSm} alt="search" width={18} height={18} />
              </Button>
            </Form.Group>
          </Form>
          <ul className="pharmacies-list-grid">
            {filteredData && filteredData?.length > 0 ? (
              filteredData.map((el, i) => <li key={i}>{el}</li>)
            ) : (
              <li>{t("no result")}</li>
            )}
          </ul>
        </div>
      </div>
    </NewLayout>
  );
};

export default PharmaciesList;
