import React, { useEffect, useState } from "react";
import { Navbar, Nav, Dropdown, Button } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./newHeader.css";
import { handleCheckUrl } from "../../utils/functions";
import { PrimaryLogo, WhiteLogo } from "./newLogo";
import { appURL } from "../../config/key";
import CookieModal from "../common/cookieModal";
import Storage from "../../utils/storage";

const NewHeader = ({ allLanguages, handleChangeLanguage, currLang }) => {
  const { t } = useTranslation();
  const path = window.location.pathname;
  const cookieValue = Storage.get("cookie");
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
  const [showCookie, setShowCookie] = useState(false);

  const handleCookieModal = () => {
    if (cookieValue === "Yes" || cookieValue === "No") {
      setShowCookie(false);
    } else {
      setShowCookie(true);
    }
  };

  const handleConfirmCookie = () => {
    Storage.set("cookie", "Yes");
    setShowCookie(false);
  };
  const handleCancelCookie = () => {
    Storage.set("cookie", "No");
    setShowCookie(false);
  };

  const handleNavbarState = () => {
    setIsNavbarCollapsed(true);
  };

  const scrollToHowWorks = () => {
    let works = document.getElementById("howWorks");
    if (works) {
      handleNavbarState();
      works.scrollIntoView({ top: 0, behavior: "smooth" });
    } else {
      window.location.href = "/#howWorks";
    }
  };
  useEffect(() => {
    handleCookieModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const keyToClear = "cookie";
    if (cookieValue === "No") {
      const clearLocalStorageKey = () => {
        localStorage.removeItem(keyToClear);
      };
      window.addEventListener("beforeunload", clearLocalStorageKey);
      return () => {
        window.removeEventListener("beforeunload", clearLocalStorageKey);
      };
    }
  }, [cookieValue]);

  return (
    <>
      <div className="new-header-wrapper">
        <div className="new-header">
          <div className="container">
            <div className="new-header-grid">
              <div className="logo-grid">
                <Link to="/" className="logo">
                  {PrimaryLogo}
                </Link>
              </div>

              <div className="new-menu-section">
                <div className="new-header-menu">
                  <Navbar expanded={!isNavbarCollapsed} expand="lg">
                    <Navbar.Toggle
                      aria-controls="basic-navbar-nav"
                      onClick={() => setIsNavbarCollapsed(!isNavbarCollapsed)}
                    />
                    <Navbar.Collapse id="responsive-navbar-nav">
                      <Nav>
                        <NavLink className="nav-link mobile-view-link" to="/">
                          {WhiteLogo}
                        </NavLink>
                        {/* <NavLink className="nav-link" to="/about">
                          <span>{t("about.heading")}</span>
                        </NavLink> */}
                        {path !== "/" ? (
                          <NavLink
                            onClick={handleNavbarState}
                            className="nav-link"
                            to="/"
                          >
                            חזרה לעמוד הראשי
                          </NavLink>
                        ) : null}
                        <NavLink className="nav-link">
                          <span onClick={scrollToHowWorks}>איך זה עובד ?</span>
                        </NavLink>

                        <NavLink
                          onClick={handleNavbarState}
                          className="nav-link"
                          to="/contact"
                        >
                          {t("header.contact")}
                        </NavLink>
                        <div className="mobile-view-btn mobile-view">
                          <Button
                            onClick={() => {
                              handleCheckUrl(`${appURL}`);
                            }}
                            className="border-btn"
                          >
                            התחבר/י
                          </Button>
                          <Button
                            className="black-btn"
                            onClick={() => {
                              handleCheckUrl(`${appURL}/meetingrequest`);
                            }}
                          >
                            שלח/י בקשה לייעוץ
                          </Button>
                        </div>
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                </div>
              </div>
              <div className="new-auth-box">
                <Button
                  onClick={() => {
                    handleCheckUrl(`${appURL}`);
                  }}
                  className="border-btn"
                >
                  התחבר/י
                </Button>
                <Button
                  className="black-btn"
                  onClick={() => {
                    handleCheckUrl(`${appURL}/meetingrequest`);
                  }}
                >
                  שלח/י בקשה לייעוץ
                </Button>

                <Dropdown className="lang-dropdown">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {currLang?.flag ? (
                      <img src={currLang?.flag} alt="icon" />
                    ) : null}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {allLanguages
                      .filter((l) => l.shortCode !== currLang?.shortCode)
                      .map((lang, index) => (
                        <Dropdown.Item
                          onClick={() => handleChangeLanguage(lang)}
                          key={lang?.id}
                          as="button"
                        >
                          <img src={lang.flag} alt="icon" /> {lang.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CookieModal
        setShowCookie={setShowCookie}
        showCookie={showCookie}
        handleConfirmCookie={handleConfirmCookie}
        handleCancelCookie={handleCancelCookie}
      />
    </>
  );
};

export default NewHeader;
