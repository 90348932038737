import { useState, useEffect, useRef, useMemo } from "react";
import Reel from "react-reel";
import { Heart } from "../common/icon";
import { Button } from "react-bootstrap";
import { handleCheckUrl } from "../../utils/functions";
import { appURL } from "../../config/key";

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIsIntersecting(entry.isIntersecting),
        { rootMargin: "-5px" }
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

const StatsSection = () => {
  const herartSection = useRef(null);
  const isInViewport1 = useIsInViewport(herartSection);

  const counters = document.querySelectorAll(".value");
  const speed = 1000;
  if (isInViewport1) {
    counters.forEach((counter) => {
      const animate = () => {
        const value = +counter.getAttribute("akhi");
        const data = +counter.innerText;

        const time = value / speed;
        if (data < value) {
          counter.innerText = Math.ceil(data + time);
          setTimeout(animate, 1);
        } else {
          counter.innerText = value;
        }
      };

      animate();
    });
  }
  const theme = {
    reel: {
      height: "56px",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "center",
      overflowY: "hidden",
      fontSize: "56px",
      fontWeight: "700",
      color: "#000",
      lineHeight: "60px",
    },
    group: {
      transitionDelay: "0ms",
      transitionTimingFunction: "ease-in-out",
      transform: "translate(0, 0)",
      height: "56px",
    },
    number: {
      height: "56px",
    },
  };
  const getReelTheme = () => ({
    ...theme,
    reel: {
      ...theme.reel,
    },
  });

  return (
    <div className="stats-section">
      <div className="container">
        <div className="stats-main-grid">
          <div className="stats-title">
            <h2>
              תוך כמה זמן <span>תמצאו לי תור?</span>
            </h2>
          </div>
          <div className="stats-grid">
            <div className="stats-box" ref={herartSection}>
              <Heart perValue={isInViewport1 ? 6 : 0} />
              <div className="reel-grid">
                <Reel
                  theme={getReelTheme()}
                  text={isInViewport1 ? "6%" : "0%"}
                />
              </div>
              <p>מהלקוחות שלנו דיברו עם רופא</p>
              <h4>אחרי יומיים</h4>
            </div>
            <div className="stats-box">
              <Heart perValue={isInViewport1 ? 21 : 0} />
              <div className="reel-grid">
                <Reel
                  theme={getReelTheme()}
                  text={isInViewport1 ? "21%" : "0%"}
                />
              </div>
              <p>מהלקוחות שלנו דיברו עם רופא</p>
              <h4>תוך יומיים</h4>
            </div>
            <div className="stats-box">
              <Heart perValue={isInViewport1 ? 51 : 0} />
              <div className="reel-grid">
                <Reel
                  theme={getReelTheme()}
                  text={isInViewport1 ? "51%" : "0%"}
                />
              </div>
              <p>מהלקוחות שלנו דיברו עם רופא</p>
              <h4>תוך יום</h4>
            </div>
            <div className="stats-box">
              <Heart perValue={isInViewport1 ? 22 : 0} />
              <div className="reel-grid">
                <Reel
                  theme={getReelTheme()}
                  text={isInViewport1 ? "22%" : "0%"}
                />
              </div>
              <p>מהלקוחות שלנו דיברו עם רופא</p>
              <h4>ביום פנייתם</h4>
            </div>
          </div>
        </div>
        <div className="feature-cta">
          <Button
            className="black-btn"
            onClick={() => {
              handleCheckUrl(`${appURL}/meetingrequest/`);
            }}
          >
            שלח/י בקשה לייעוץ
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StatsSection;
