import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./faq.css";
import FaqList from "../newHome/faqList";
import NewLayout from "../layout/newLayout";

const Faq = () => {
  const { t } = useTranslation();
  return (
    <NewLayout>
      <div className="faq-page">
        <div className="container">
          <div className="faq-title">
            <h1>{t("faq.heading")}</h1>
            <p dangerouslySetInnerHTML={{ __html: t("faq.paragraph") }} />
            <div className="faq-btn-grid">
              <Link to="/contact" className="black-btn">
                {t("contact.heading")}
              </Link>
            </div>
          </div>
          <FaqList />
        </div>
      </div>
    </NewLayout>
  );
};

export default Faq;
