import { Button } from "react-bootstrap";
import { StarIcon } from "../common/icon";
import { handleCheckUrl } from "../../utils/functions";
import { appURL } from "../../config/key";

const Testimonial = () => {
  return (
    <div className="testimonial-section">
      <div className="container">
        <div className="testimonial-title">
          <h2>הם יודעים מה הם אומרים</h2>
        </div>
        <div className="testimonial-grid">
          <div className="testimonial-sub-grid">
            <div className="testimonial-box">
              <div className="star-grid">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
              <div className="testimonial-text">
                <h6>
                  “חווית יעוץ מהטובות שהיו לי... רופא מדהים, סובלני, יסודי ...
                  המון תודה שאתם קיימים”
                </h6>
              </div>
              <div className="testimonial-name">
                <h6>לקוח מרוצה</h6>
                <p>נוירולוגיה</p>
              </div>
            </div>

            <div className="testimonial-box">
              <div className="star-grid">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
              <div className="testimonial-text">
                <h6>“שירות מושלם לא מבינה איך לא שמעתי עליו קודם”</h6>
              </div>
              <div className="testimonial-name">
                <h6>לקוח מרוצה</h6>
                <p>גסטרואנטרולוגיה</p>
              </div>
            </div>
          </div>

          <div className="testimonial-sub-grid">
            <div className="testimonial-box">
              <div className="star-grid">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
              <div className="testimonial-text">
                <h6>
                  “היה מצוין תודה, רופא ממש טוב יודע להסביר תעצמו כיף שאפשר
                  לעלות תבדיקות דימות באתר חוסך הרבה ובכללי אחלה של פתרון מהיר
                  לקבל רופא”
                </h6>
              </div>
              <div className="testimonial-name">
                <h6>לקוח מרוצה</h6>
                <p>אורתופדיה</p>
              </div>
            </div>

            <div className="testimonial-box mobile-view-hide">
              <div className="star-grid">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
              <div className="testimonial-text">
                <h6>
                  “חווית השימוש שלי נהדרת. מענה תוך זמן אפס ובכל שעה, זמינות תור
                  מיידית וההתקשרות עם ד"ר ישראלי הייתה לי נעימה אינפורמטיבית
                  ועניינית. מערכת התמיכה של האתר נפלאה עד כדי פליאה. ממש כיף
                  לדעת שיש אכן שירות לקוחות שבאמת עוזר!! זה מצרך נדיר בימינו אלו
                  תמשיכו כך! תודה ללור שעזרה לי מאוד בהעברת הקבצים לרופאה כדי
                  שהפגישה תהיה יעילה”
                </h6>
              </div>
              <div className="testimonial-name">
                <h6>לקוח מרוצה</h6>
                <p>אנדוקרינולוגיה</p>
              </div>
            </div>
          </div>
          <div className="testimonial-sub-grid tab-view-hide">
            <div className="testimonial-box">
              <div className="star-grid">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
              <div className="testimonial-text">
                <h6>
                  “שירות אדיב, נח למשתמש, אפשרות למפגש מיידי, רופאה מקצועית
                  מאוד, סיכום טיפול מרשם שמופיע במחשב מאוד נח”
                </h6>
              </div>
              <div className="testimonial-name">
                <h6>לקוח מרוצה</h6>
                <p>רפואת עור</p>
              </div>
            </div>

            <div className="testimonial-box">
              <div className="star-grid">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
              <div className="testimonial-text">
                <h6>
                  “אני חייבת לפרגן לכם ולהגיד שהייתי צריכה תור מהרגע להרגע ופשוט
                  הצלתם אותי! איזה שירות מטורף! כל הכבוד לכם!!!”
                </h6>
              </div>
              <div className="testimonial-name">
                <h6>לקוח מרוצה</h6>
                <p>גניקולוגיה</p>
              </div>
            </div>
          </div>
        </div>
        <div className="feature-cta">
          <Button
            className="black-btn"
            onClick={() => {
              handleCheckUrl(`${appURL}/meetingrequest`);
            }}
          >
            שלח/י בקשה לייעוץ
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
