import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import { serverPath } from "../../config/key";
import InfoModal from "../common/infoModal";

const ContactForm = () => {
  const { t } = useTranslation();
  // inital state
  const contactInit = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  };

  // States
  const [userGeoData, setUserGeoData] = useState({});
  const [fieldState, setFieldState] = useState(contactInit);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState(fieldState);
  const [show, setShow] = useState(false);
  const [formStatus, setFormStatus] = useState("");
  // const [isChecked, setIsChecked] = useState(false);

  // const handleIsCheck = () => {
  //   setIsChecked(!isChecked);
  // };

  // utils function
  const handleShow = () => setShow(!show);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldState({ ...fieldState, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleChangePhone = (value) => {
    setFieldState({ ...fieldState, phone: value });
    setErrors({ ...errors, phone: "" });
  };

  const validateContactField = () => {
    let valid = true;
    let error = {};
    if (fieldState.firstName.trim() === "") {
      error.firstName = t("info.firstName");
      valid = false;
    }
    if (fieldState.lastName.trim() === "") {
      error.lastName = t("info.lastName");
      valid = false;
    }
    if (fieldState.phone.trim() === "") {
      error.phone = t("info.phone");
      valid = false;
    }
    if (fieldState.email.trim() === "") {
      error.email = t("info.email");
      valid = false;
    }
    // if (!isChecked) {
    //   error.isCheck = "זה נדרש";
    //   valid = false;
    // }
    return { valid, error };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    setErrors({});
    const contactValidation = validateContactField();
    if (!contactValidation.valid) {
      setErrors({ ...contactValidation.error });
      setLoader(false);
      return;
    }
    try {
      const response = await axios.post(`${serverPath}/public/contact`, {
        ...fieldState,
      });
      const {
        data: { code },
      } = response || {};

      if (code === 200) {
        handleShow();
        setFormStatus("success");
        setLoader(false);
        setFieldState({});
        // setIsChecked(false);
      }
    } catch (error) {
      setLoader(false);
      setFormStatus("error");
      handleShow();
    }
  };

  const originData = async () => {
    const res = await axios.get("https://ipapi.co/json");
    setUserGeoData(res.data);
  };
  useEffect(() => {
    originData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="new-contact-form">
        <Form onSubmit={handleSubmit}>
          <div className="form-group-grid">
            <Form.Group className="form-group">
              <Form.Label>שם פרטי</Form.Label>
              <Form.Control
                type="text"
                placeholder="שם פרטי"
                name="firstName"
                onChange={handleChange}
                value={fieldState.firstName || ""}
              />
              {!!errors?.firstName && (
                <p className="text-danger">{errors?.firstName}</p>
              )}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>שם משפחה</Form.Label>
              <Form.Control
                type="text"
                placeholder="שם משפחה"
                name="lastName"
                onChange={handleChange}
                value={fieldState.lastName || ""}
              />
              {!!errors?.lastName && (
                <p className="text-danger">{errors?.lastName}</p>
              )}
            </Form.Group>
          </div>
          <div className="form-group-grid">
            <Form.Group className="form-group">
              <Form.Label>טלפון ליצירת קשר</Form.Label>
              <PhoneInput
                country={userGeoData?.country_code?.toLowerCase() || "il"}
                onChange={handleChangePhone}
                inputProps={{
                  name: "phone",
                  placeholder: t("contact.phonePlaceholder"),
                }}
           
                value={fieldState.phone}
              />
              {!!errors?.phone && (
                <p className="text-danger">{errors?.phone}</p>
              )}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>אימייל</Form.Label>
              <Form.Control
                type="email"
                placeholder={t("contact.emailPlaceholder")}
                name="email"
                onChange={handleChange}
                value={fieldState.email || ""}
              />
              {!!errors?.email && (
                <p className="text-danger">{errors?.email}</p>
              )}
            </Form.Group>
          </div>
          <Form.Group className="form-group">
            <Form.Label>{t("contact.message")}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder={t("contact.msgPlaceholder")}
              name="message"
              onChange={handleChange}
              value={fieldState.message || ""}
            />
          </Form.Group>
          <Form.Group className="form-group" controlId="formBasicCheckbox">
            <Form.Label>
              מידע שמסרת ותמסור, ברצונך ובהסכמתך, יישמר אצל ואלור (היידוק) וישמש
              אותנו לצורך דיוור ישיר,ניתוח מידע ומתן השירותים. המידע יועבר
              לשותפים עסקיים ולספקים (לרבות רופאים) לצרכים האמורים
            </Form.Label>
            {/* <Form.Check
              type="checkbox"
              label="מסכים לתנאי השימוש"
              checked={isChecked}
              onChange={handleIsCheck}
            />
            {!!errors?.isCheck && <p className="text-danger">{"זה נדרש"}</p>} */}
          </Form.Group>
          <Button className="black-btn" type="submit" disabled={loader}>
            {loader ? <Spinner animation="border" /> : "שלח הודעה"}
          </Button>
        </Form>
      </div>

      <InfoModal show={show} handleShow={handleShow} formStatus={formStatus} />
    </>
  );
};

export default ContactForm;
