import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { ErrorIcon, SuccessIcon } from "./icon";

const InfoModal = ({ show, handleShow, formStatus }) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={handleShow} centered className="new-info-modal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {formStatus === "success" ? (
          <div className="success-icon-grid">
            <SuccessIcon />
          </div>
        ) : (
          <div className="error-icon-grid">
            <ErrorIcon />
          </div>
        )}
        <h6>
          {formStatus === "success" ? t("info.success") : t("info.error")}
        </h6>
      </Modal.Body>
      <Modal.Footer>
        <Button className="border-btn" onClick={handleShow}>
          {t("info.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InfoModal;
