import ContactForm from "../contact/contactForm";

const ContactSection = () => {
  return (
    <>
      <div className="contact-section" id="contact-section">
        <div className="container">
          <div className="new-contact-title">
            <h2>צור קשר איתנו</h2>
            <p>מבטיחים לחזור אליך במהרה</p>
          </div>
          <ContactForm />
        </div>
      </div>
    </>
  );
};

export default ContactSection;
