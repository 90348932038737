import { useEffect, useState } from "react";
import Slider from "react-slick";
import { Button } from "react-bootstrap";
import { TickIcon } from "../common/icon";
import Storage from "../../utils/storage";
import { handleCheckUrl } from "../../utils/functions";
import { appURL } from "../../config/key";

const ExpertSlider = () => {
  const languageKey = Storage.get("lang")?.apiShortCode
    ? Storage.get("lang")?.apiShortCode
    : "heb";
  const settings = {
    dots: true,
    cssEase: "linear",
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    centerPadding: "50px",
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          autoplay: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          autoplay: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          dots: false,
        },
      },
    ],
  };
  const ourExpertList = [
    {
      id: 1,
      eng: "Dr. Achi Friedman",
      heb: "ד״ר אכי פרידמן",
      category: "רפואת עור ומין",
    },
    {
      id: 2,
      eng: "Dr. Alex Krauthammer",
      heb: "ד״ר אלכס קראוטהמר ",
      category: "גסטרואנטרולוגיה ילדים",
    },
    {
      id: 3,
      eng: "Dr. Amir Weintraub",
      heb: "ד״ר אמיר וינטראוב",
      category: "פוריות וגינקולוגיה",
    },
    {
      id: 4,
      eng: "Dr. Anna Zaidin",
      heb: "ד״ר אנה זיידין",
      category: "רפואת נשים",
    },
    {
      id: 5,
      eng: "Dr. Esnat Zamora",
      heb: "ד״ר אסנת זמורה",
      category: "כירורגית ילדים",
    },
    {
      id: 6,
      eng: "Dr. Assaf Hilleli",
      heb: "ד״ר אסף הללי",
      category: "רפואת עיניים",
    },
    {
      id: 7,
      eng: "Dr. Efrat Solomon Cohen",
      heb: "ד״ר אפרת סולומון כהן",
      category: "רפואת עור ומין",
    },
    {
      id: 8,
      eng: "Dr. Bat Sheva Marcus",
      heb: "ד״ר בת שבע מרקוס",
      category: "רפואת עור ומין",
    },
    {
      id: 9,
      eng: "Dr. Gadi Shlomai",
      heb: "ד״ר גדי שלומאי",
      category: "אנדוקרינולוגיה",
    },
    {
      id: 10,
      eng: "Dr. Gil Ben Yaakov",
      heb: "ד״ר גיל בן יעקוב",
      category: "גסטרואנטרולוגיה ומחלות כבד",
    },
    {
      id: 11,
      eng: "Dr. Galit Israeli",
      heb: "ד״ר גלית ישראלי",
      category: "אנדוקרינולוגיה ילדים",
    },
    {
      id: 12,
      eng: "Dr. Dan Elian",
      heb: "ד״ר דן אליאן",
      category: "מחלות לב וצינתורים",
    },
    {
      id: 13,
      eng: "Dr. Dan Pert",
      heb: "ד״ר דן פרט",
      category: "כירורגיה אורתופדית, אורתופדיה קרסול וכף רגל",
    },
    {
      id: 14,
      eng: "Dr. Tal Harel",
      heb: "ד״ר טל הראל ",
      category: "רפואת שיכוך כאב",
    },
    {
      id: 15,
      eng: "Dr. Yair Skornik",
      heb: "ד״ר יאיר סקורניק",
      category: "אנדוקרינולוגיה",
    },
    {
      id: 16,
      eng: "Dr. Yoram Feitelson",
      heb: "ד״ר יורם פייטלסון",
      category: "אלרגיה ואימונולוגיה קלינית",
    },
    {
      id: 17,
      eng: "Dr. Yaniv Avner",
      heb: "ד״ר יניב אבנר",
      category: "אף אוזן גרון ילדים",
    },
    {
      id: 18,
      eng: "Dr. Meir Krupsky",
      heb: "ד״ר מאיר קרופסקי",
      category: "מחלות ריאה ודרכי נשימה מומחה במחלות פנימיות, רופא צלילה",
    },
    {
      id: 19,
      eng: "Dr. Michal Dviri",
      heb: "ד״ר מיכל דבירי",
      category: "רפואת נשים, פוריות",
    },
    {
      id: 20,
      eng: "Dr. Rabina Gilad",
      heb: "ד״ר רבינא גלעד",
      category: "מחלות וניתוחי רשתית",
    },
    {
      id: 21,
      eng: "Dr. Ron Milo",
      heb: "ד״ר רון מילוא",
      category: "נוירולוגיה ונוירואימונולוגיה",
    },
    {
      id: 22,
      eng: "Dr. Nimrod Kon",
      heb: "נמרוד קון",
      category: "פנימית ורפואה פליאטיבית",
    },
    {
      id: 23,
      eng: "Dr. Anet Yerushalmi Peler",
      heb: "ענת ירושלמי פלר",
      category: " גסטרו ילדים",
    },
    {
      id: 24,
      eng: "Dr. Yael Raz Yana",
      heb: "יעל רז יאנה",
      category: "גניקולוגיה, גניקולוגיה אונקולוגית",
    },
    {
      id: 25,
      eng: "Dr. Lior Zornitzky",
      heb: "ליאור זורניצקי",
      category: "פנימית",
    },
    {
      id: 26,
      eng: "Dr. Sharon Blum",
      heb: "שרון בלום",
      category: "רופאת עיניים ילדים ופזילה",
    },
    {
      id: 27,
      eng: "Dr. Elad Shemesh",
      heb: "אלעד שמש",
      category: "פנימית, אנדוקרינולוגיה",
    },
    {
      id: 28,
      eng: "Dr. Saliman Muad",
      heb: "סלימאן מועאד",
      category: "קרדיולוגיה",
    },
    {
      id: 29,
      eng: "Dr. Michal Lopher Perel",
      heb: "מיכל לאופר פרל",
      category: "קרדיולוגיה ",
    },
    {
      id: 30,
      eng: "Dr. Tali Kafua",
      heb: "טלי קפואה",
      category: "רפואת ילדים, חירום ילדים",
    },
    {
      id: 31,
      eng: "Dr. Amir Birger",
      heb: "אמיר בירגר",
      category: "קרדיולוגית ילדים",
    },
    {
      id: 32,
      eng: "Dr. Ben Sade",
      heb: "בן שדה",
      category: "קרדיולוגיה, אי ספיקת לב ויתר לחץ דם ריאתי.",
    },
    {
      id: 33,
      eng: "Dr. Natali Noam",
      heb: "נטלי נועם",
      category: "כירורגית כלי דם",
    },
    {
      id: 34,
      eng: "Dr. Dor Ravid",
      heb: "דור רביד",
      category: "פנימית",
    },
    {
      id: 35,
      eng: "Dr. Michal Kaal",
      heb: "מיכל קהל",
      category: "ילדים/ ריאות",
    },
    {
      id: 36,
      eng: "Dr. Tsipora Noyman Mauda",
      heb: "צפורה נוימן מעודה",
      category: "פנימית והמטולוגיה",
    },
    {
      id: 37,
      eng: "Dr. Meirav Chashin Bekanshti",
      heb: "מירב חשין בקנשטיין",
      category: "ראומטולוגית ילדים, ילדים ",
    },
  ];

  return (
    <div className="new-our-expert" id="experts-section">
      <div className="container">
        <div className="new-expert-title">
          <h2>
            {" "}
            נבחרת של <span>רופאים בכירים</span> ואיכותיים במעל 40 תחומים.
          </h2>
          <div className="new-expert-list">
            <ul>
              <li>
                <TickIcon /> אנדוקרינולוגיה
              </li>
              <li>
                <TickIcon /> קרדיולוגיה
              </li>
              <li>
                <TickIcon /> רפואת עור
              </li>
              <li>
                <TickIcon /> גסטרואנטרולוגיה
              </li>
              <li>
                <TickIcon /> רפואת נשים
              </li>
              <li>
                <TickIcon /> ועוד
              </li>
            </ul>
          </div>
        </div>
        <div className="new-expert-slider">
          <Slider {...settings}>
            {ourExpertList.map((el) => (
              <div className="new-expert" key={el?.id}>
                <div className="new-expert-profile">
                  <ExpertImage el={el} />
                  <div className="new-expert-content">
                    <h6>{el[languageKey]}</h6>
                    <p>
                      {el.category} <TickIcon />
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="feature-cta">
          <Button
            className="black-btn"
            onClick={() => {
              handleCheckUrl(`${appURL}/meetingrequest`);
            }}
          >
            שלח/י בקשה לייעוץ
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ExpertSlider;

const ExpertImage = ({ el }) => {
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const imageName = el.eng?.split(" ").join("-");
    const pngSrc = `/doctor-profile/${imageName}.png`;
    const jpgSrc = `/doctor-profile/${imageName}.jpg`;

    const img = new Image();
    img.src = pngSrc;

    img.onload = () => {
      setImageSrc(pngSrc);
    };

    img.onerror = () => {
      setImageSrc(jpgSrc);
    };
  }, [el.eng]);

  return <img src={imageSrc} alt={el.eng} />;
};
