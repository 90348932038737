import { Button } from "react-bootstrap";
import doctorImage from "../../assets/images/new/doctor.png";
import { handleCheckUrl } from "../../utils/functions";
import { appURL } from "../../config/key";

const HowWorks = () => {
  return (
    <div className="how-works" id="howWorks">
      <div className="container">
        <div className="feature-title">
          <h2>
            ? בואו לגלות, <span> איך זה עובד</span>
          </h2>
        </div>
        <div className="how-works-grid">
          <div className="how-works-left">
            <img src={doctorImage} alt="icon" className="img-fluid" />
          </div>
          <div className="how-works-right">
            <div className="how-works-box">
              <div className="how-works-box-grid">
                <div className="how-works-box-right">
                  <h6>בחר/י תחום ואת סיבת הפנייה</h6>
                  <p>
                    בחרו מבין 40 תחומי ההתמחות, לחצו על ״שלח בקשה לייעוץ״, סמנו
                    אם פונים בקשר לילד/מבוגר והזינו את סיבת הפניה. אנחנו נמצא את
                    הרופא המתאים ביותר!
                  </p>
                </div>
                <div className="how-works-box-left">
                  <h6>1</h6>
                </div>
              </div>
            </div>
            <div className="how-works-box">
              <div className="how-works-box-grid">
                <div className="how-works-box-right">
                  <h6>בחר/י מועד שנוח לך לשיחה</h6>
                  <p>
                    ניתן לבקש תור בהקדם האפשרי (שני ימי עסקים) או בחלון זמן אחר.
                    לאחר הזנת פרטים אישיים, הבקשה תישלח לכל הרופאים הרלוונטיים
                    ברשת הרופאים שלנו.
                  </p>
                </div>
                <div className="how-works-box-left">
                  <h6>2</h6>
                </div>
              </div>
            </div>
            <div className="how-works-box">
              <div className="how-works-box-grid">
                <div className="how-works-box-right">
                  <h6>קבל/י פרטים לגבי הרופא/ה והתור המוצע לך</h6>
                  <p>
                    תקבל/י הודעת וואטסאפ שמצאנו לך רופא/ה זמינים, עם מועדים
                    ספציפיים אופציונאליים. כל שנותר הוא לבחור מועד ולאשר!
                  </p>
                </div>
                <div className="how-works-box-left">
                  <h6>3</h6>
                </div>
              </div>
            </div>
            <div className="how-works-box">
              <div className="how-works-box-grid">
                <div className="how-works-box-right">
                  <h6>בצע/י תשלום</h6>
                  <p>
                    לאחר בחירת המועד תועבר/י לעמוד תשלום באשראי. השירות שלנו
                    עולה 480 ש״ח, פחות מחצי מהמחיר הממוצע לשירות רפואי פרטי
                    בארץ.
                  </p>
                </div>
                <div className="how-works-box-left">
                  <h6>4</h6>
                </div>
              </div>
            </div>
            <div className="how-works-box">
              <div className="how-works-box-grid">
                <div className="how-works-box-right">
                  <h6>התחבר/י במועד שנקבע ותיפגש/י עם המומחה שלך אונליין</h6>
                  <p>
                    בטרם השיחה ניתן להעלות מסמכים לצפיית הרופא, בהגיע מועד התור
                    התחבר/י לזום, ובסיום תקבל/י סיכום טיפול ולפי צורך מרשם שנשלח
                    ישירות לסופר פארם.
                  </p>
                </div>
                <div className="how-works-box-left">
                  <h6>5</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="feature-cta">
          <Button
            className="black-btn"
            onClick={() => {
              handleCheckUrl(`${appURL}/meetingrequest`);
            }}
          >
            שלח בקשה לייעוץ
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HowWorks;
