import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./newFooter.css";
import { WhiteLogo } from "../Header/newLogo";
import { FacebookIcon, InstagramIcon, LinkedInIcon } from "../common/icon";
import { appURL } from "../../config/key";

const NewFooter = () => {
  const { t } = useTranslation();
  return (
    <div className="new-footer">
      <div className="container">
        <div className="new-top-footer">
          <div className="footer-left">
            <div className="new-footer-logo">
              <Link to="/">{WhiteLogo}</Link>
            </div>
            <div className="new-footer-social">
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/hidocisrael?mibextid=9R9pXO"
                  >
                    {FacebookIcon}
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://instagram.com/hidoc_israel?igshid=MzRlODBiNWFlZA=="
                  >
                    {InstagramIcon}
                  </a>
                </li>
                {/* <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/israelhidoc"
                  >
                    {TwitterIcon}
                  </a>
                </li> */}
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/hidoc-israel/"
                  >
                    {LinkedInIcon}
                  </a>
                </li>
              </ul>
            </div>

            <div className="new-address">
              <h6>צור קשר:</h6>
              <a href="tel:0732072226">073-2072226</a>
              <a href="mailto:support@hidoc.co.il">support@hidoc.co.il</a>
            </div>
          </div>

          <div className="new-footer-menu">
            <div className="new-footer-links">
              <ul className="new-links-list">
                קישורים
                <li>
                  <a href="https://madanes.com/"> {t("footer.madness")}</a>
                </li>
                <li>
                  <Link to={`${appURL}/DoctorRegistration`}>
                    {t("footer.joinUs")}
                  </Link>
                </li>
                <li>
                  <Link to="/faq_clients">שאלות תשובות</Link>
                </li>
              </ul>
              <ul className="new-links-list">
                טפסים
                <li>
                  <a href={`${appURL}/consent`}> {t("footer.informed")}</a>
                </li>
                <li>
                  <Link to="/privacy"> {t("footer.privacy")}</Link>
                </li>
                <li>
                  <a href={`${appURL}/terms`}>{t("footer.terms")} </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="new-middle-footer">
          <p>
            האתר אינו מיועד למצבי חירום, אין להשתמש באתר זה במצב מסכן חיים.
            לקבלת עזרה מיידית חייגו 101.
          </p>
        </div>

        <div className="new-bottom-footer">
          <div className="new-footer-grid">
            <div className="footer-grid-left">
              <p>מחזיקים בתקני אבטחת מידע ISO 27701 ו ISO 27799</p>
            </div>
            <div className="footer-grid-center">
              <p>Designed with 💜 by Uniqcom Studio</p>
            </div>
            <div className="footer-grid-right">
              <p>2024 Hidoc. All right reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFooter;
