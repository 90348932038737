import { Button } from "react-bootstrap";
import avtar1 from "../../assets/images/new/doctor3.png";

const DoctorProfile = () => {
  return (
    <div className="doctor-profile-section">
      <div className="container">
        <div className="doctor-profile-grid">
          <div className="doctor-avtar">
            <img src={avtar1} alt="avtar" />
          </div>
          <div className="doctor-degree">
            <h2>ישראל ישראלי</h2>
            <h3>מנהל השירת לכבד שומני בית חולים שיבא</h3>
          </div>
          <div className="doctor-spailciality-grid">
            <div className="doctor-spailciality">
              <Button className="pink-btn">כירורג</Button>
              <Button className="pink-btn">גניקולוג</Button>
            </div>
            <Button className="black-btn">בוא נבחר את הרופא שלך</Button>
          </div>
          <div className="doctor-additional">
            <p>
              תוספת לימודים כאן ועוד פרטים מעניינים על הרופא. מומחה מהמובילים
              בישראל, במגוון רחב של תחומים, בזמינות גבוהה ובמחיר נגיש ותהנה
              מייעוץ אונליין שמאפשר לך לקיים את השיחה מכל מקום שנוח לך.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorProfile;
