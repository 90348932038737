import { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import searchIconSm from "../../assets/images/new/search-icon-sm.svg";
import search from "../../assets/images/new/search.svg";
import Storage from "../../utils/storage";
import AppContext from "../../context/appContext";
import AllCategoryData from "../../components/consulting/category.json";

const SearchComponent = () => {
  const { t } = useTranslation();
  const { setSingleConsulting } = useContext(AppContext);
  const lang = Storage.get("lang")?.apiShortCode;
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState(false);
  const [categoriesData, setcategoriesData] = useState(AllCategoryData);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSearchResult(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const getAllConsultingFields = async () => {
    setcategoriesData(AllCategoryData);
  };

  const handleSearch = (e) => {
    setSearchResult(true);
    const searchWord = e.target.value;
    setSearchText(searchWord);
    const newFilter = categoriesData.filter((value) => {
      return value?.title?.toLowerCase()?.includes(searchWord?.toLowerCase());
    });

    if (searchWord === "") {
      setFilteredData("");
    } else {
      setFilteredData(newFilter);
    }
  };
  const handleSelectResult = (text) => {
    setSearchText(text);
    setSearchResult(false);
  };
  const handleRedirect = () => {
    if (searchText !== "") {
      navigate(`/consulting-detail/?searchKey=${searchText}`);
      setSingleConsulting(searchText);
    }
  };

  useEffect(() => {
    getAllConsultingFields();
  }, []);
  const boldQuery = (item) => {
    const text = item.title;
    const termSp = searchText.split("");
    const textSp = text.split("");
    const result = textSp.map((el) => {
      if (termSp.includes(el.toLowerCase())) {
        return `<b>${el}</b>`;
      }
      return el;
    });
    return result.join("");
  };

  return (
    <div className="search-input" ref={wrapperRef}>
      <Form onSubmit={handleRedirect}>
        <Form.Group className="search-input-form">
          <Form.Control
            type="search"
            placeholder={t("Specialty...")}
            value={searchText}
            onChange={handleSearch}
            required
          />
          <img
            src={search}
            alt="search"
            className="search-icon"
            width={24}
            height={24}
          />
          <Button
            className="black-btn"
            type="submit"
            // disabled={filteredData?.length === 0}
          >
            <img src={searchIconSm} alt="search" width={18} height={18} />
          </Button>
        </Form.Group>
        {searchResult && searchText !== "" ? (
          <SearchResultComponent
            filteredData={filteredData}
            boldQuery={boldQuery}
            searchText={searchText}
            handleSelectResult={handleSelectResult}
            lang={lang}
          />
        ) : null}
      </Form>
    </div>
  );
};

export default SearchComponent;

const SearchResultComponent = ({
  filteredData,
  boldQuery,
  handleSelectResult,
  lang,
}) => {
  return (
    <div className="search-result-section">
      <ul>
        {filteredData && filteredData?.length > 0 ? (
          filteredData.map((data, i) => {
            return (
              <li
                dangerouslySetInnerHTML={{
                  __html: `${boldQuery(data)}`,
                }}
                key={i}
                onClick={() => handleSelectResult(data?.title)}
              />
            );
          })
        ) : (
          <li>no result</li>
        )}
      </ul>
    </div>
  );
};
