import Lottie from "react-lottie";
import { Button } from "react-bootstrap";
import * as animationData from "./hero.json";
import SearchComponent from "./searchComponent";
import { handleCheckUrl } from "../../utils/functions";
import { appURL } from "../../config/key";

const HeroSection = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="new-hero-section">
      <div className="container">
        <div className="new-hero-grid">
          <div className="new-hero-left">
            <Lottie options={defaultOptions} />
          </div>
          <div className="new-hero-right">
            <div className="hero-main-heading">
              <h1>
                בריאות <span>פרימיום</span> לכולם בכל מקום ובכל זמן.
              </h1>
              <p>
                <span>Hidoc</span> עוזרת לאנשים לדבר עם רופא, הכי מהר ובאופן הכי
                נוח שאפשר! קבע תור עם רופא מומחה מהמובילים בישראל, במגוון רחב של
                תחומים, בזמינות גבוהה ובמחיר נגיש ותהנה מייעוץ אונליין שמאפשר לך
                לקיים את השיחה מכל מקום שנוח לך.
              </p>
            </div>
            <div className="search-input-section">
              <SearchComponent />
            </div>
            <div className="hero-cta-section">
              <Button
                className="black-btn"
                onClick={() => {
                  handleCheckUrl(`${appURL}/meetingrequest`);
                }}
              >
                שלח/י בקשה לייעוץ
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
