import { Button } from "react-bootstrap";
import doctorBox from "../../assets/images/new/doctor-box.svg";
import { handleCheckUrl } from "../../utils/functions";
import { appURL } from "../../config/key";

const ConsultingBanner = ({ singleConsulting }) => {
  return (
    <div className="consulting-banner">
      <div className="container">
        <div className="consulting-banner-grid">
          <div className="consulting-banner-left">
            <img src={doctorBox} alt="icon" />
          </div>
          <div className="consulting-banner-right">
            <h1>{singleConsulting?.title}</h1>
            <Button
              className="black-btn"
              onClick={() => {
                handleCheckUrl(
                  `${appURL}/meetingrequest${
                    singleConsulting && singleConsulting !== "null"
                      ? `?expertise=${singleConsulting?.title}`
                      : ""
                  }`
                );
              }}
            >
              שלח/י בקשה לייעוץ
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultingBanner;
