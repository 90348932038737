import { appURL } from "../../config/key";
import { handleCheckUrl } from "../../utils/functions";

const UTMBanner = ({ utm }) => {
  return (
    <div className="utm-banner-section">
      <div className="container">
        <div className="utm-banner-grid">
          {utm === "צוות" ? (
            <span
              onClick={() => {
                handleCheckUrl(`${appURL}/meetingrequest`);
              }}
            >
              הטבה מיוחדת ללקוחות צוות - ייעוץ רפואי אונליין בעלות 430 שח בלבד
            </span>
          ) : (
            <span
              onClick={() => {
                handleCheckUrl(`${appURL}/meetingrequest`);
              }}
            >
              הטבה מיוחדת ללקוחות LIFE STYLE - ייעוץ רפואי אונליין בעלות 430 שח
              בלבד
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default UTMBanner;
