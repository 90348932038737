import { useSearchParams } from "react-router-dom";
import HeroSection from "./heroSection";
import "./newHome.css";
import NewLayout from "../layout/newLayout";
import OurFeature from "./ourFeature";
import StatsSection from "./statsSection";
import HowWorks from "./howWorks";
import MedicineLogo from "./medicineLogo";
import Testimonial from "./testimonial";
import ExpertSlider from "./expertSlider";
import FaqSection from "./faqSection";
import ContactSection from "./contactSection";
import UTMBanner from "./utmBanner";
import IchilovBanner from "../partner/ichilov";

const NewHome = () => {
  const [searchParams] = useSearchParams();
  const utm = searchParams.get("utm");
  return (
    <NewLayout>
      <div className="new-home">
        <HeroSection />
        {utm === "lifestyle2023" || utm === "צוות" ? (
          <UTMBanner utm={utm} />
        ) : null}
        <IchilovBanner />
        <OurFeature />
        <StatsSection />
        <HowWorks />
        <MedicineLogo />
        <Testimonial />
        <ExpertSlider />
        <FaqSection />
        <ContactSection />
      </div>
    </NewLayout>
  );
};

export default NewHome;
