import { useTranslation } from "react-i18next";
import "./about.css";
import NewLayout from "../layout/newLayout";

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <NewLayout>
      <div className="about">
        <div className="container">
          <h2> {t("about.heading")}</h2>
          <div className="about-content">
            {/* <div className="about-video">
              <iframe
                width="100%"
                height="500px"
                class="about-video-iframe"
                src="https://www.youtube.com/embed/tAnjsCj1y8Q"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </div> */}
            <div className="about-description">
              <h1>{t("about.subHeading")}</h1>
              <p>{t("about.list1")}</p>
              <p>{t("about.list2")}</p>
              <p>{t("about.list3")}</p>
              <p>{t("about.list4")}</p>
              <p>{t("about.list5")}</p>
              <p>{t("about.list6")}</p>
              <p>
                <b>{t("about.list7")}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </NewLayout>
  );
};

export default AboutUs;
