import { Button } from "react-bootstrap";
import Slider from "react-slick";
import { handleCheckUrl } from "../../utils/functions";
import { appURL } from "../../config/key";

const MedicineLogo = () => {
  const settings = {
    dots: false,
    cssEase: "linear",
    className: "slider variable-width",
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    centerPadding: "50px",
    centerMode: false,
    draggable: true,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          autoplay: true,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          autoplay: true,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          variableWidth: false,
        },
      },
    ],
  };
  return (
    <div className="medicine-logo-section">
      <div className="medicine-setion-title">
        <h2>איתנו במסע לאלטרנטיבה טובה יותר בבריאות</h2>
        <p>הם בחרו לעבוד איתנו בשיתוף פעולה ואנחנו ממש שמחים על כך.</p>
      </div>
      <div className="medicine-slider-section">
        <Slider {...settings}>
          <div className="medicine-slider-logo">
            <img src="./medicine-logo/ayalon.png" alt="icon" />
          </div>
          <div className="medicine-slider-logo">
            <img src="./medicine-logo/idf.png" alt="icon" />
          </div>
          <div className="medicine-slider-logo">
            <img src="./medicine-logo/madanes.webp" alt="icon" />
          </div>
          <div className="medicine-slider-logo">
            <img src="./medicine-logo/tzevet.png" alt="icon" />
          </div>
          <div className="medicine-slider-logo">
            <img src="./medicine-logo/my-doctor.svg" alt="icon" />
          </div>
          {/* <div className="medicine-slider-logo">
            <img src="./medicine-logo/life-style.png" alt="icon" />
          </div> */}
          <div className="medicine-slider-logo">
            <img src="./medicine-logo/super-pharm.png" alt="icon" />
          </div>
        </Slider>
      </div>
      <div className="feature-cta">
        <Button
          className="black-btn"
          onClick={() => {
            handleCheckUrl(`${appURL}/meetingrequest`);
          }}
        >
          שלח/י בקשה לייעוץ
        </Button>
      </div>
    </div>
  );
};

export default MedicineLogo;
