export const PrimaryLogo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="110"
    height="30"
    viewBox="0 0 110 30"
    fill="none"
  >
    <path
      d="M17.9485 29.4425V18.5788H7.17939V29.3479H1.14113e-06L0 0.630371L7.17939 0.630371L7.17939 11.4514H17.9485V0.630371L25.1279 0.630371V29.4425H17.9485Z"
      fill="#1E0F3C"
    />
    <path
      d="M88.483 18.911C88.483 20.4408 88.2112 21.8449 87.6692 23.1232C87.1271 24.403 86.3769 25.5073 85.4171 26.4392C84.4572 27.3711 83.325 28.0933 82.0187 28.6074C80.711 29.1215 79.3085 29.3794 77.8065 29.3794C76.3046 29.3794 74.9006 29.1215 73.5943 28.6074C72.2866 28.0933 71.1605 27.3711 70.2162 26.4392C69.2703 25.5073 68.5263 24.403 67.9843 23.1232C67.4422 21.8449 67.1704 20.4408 67.1704 18.911C67.1704 17.3811 67.4422 15.9848 67.9843 14.719C68.5263 13.4547 69.2703 12.3628 70.2162 11.4449C71.1605 10.5269 72.2866 9.81248 73.5943 9.29683C74.9006 8.78273 76.3046 8.5249 77.8065 8.5249C79.3085 8.5249 80.711 8.78273 82.0187 9.29683C83.325 9.81093 84.4588 10.5269 85.4171 11.4449C86.3754 12.3628 87.1271 13.4531 87.6692 14.719C88.2112 15.9848 88.483 17.3811 88.483 18.911ZM84.3967 18.9312C84.3967 17.9884 84.2507 17.0875 83.9571 16.2286C83.6636 15.3697 83.2395 14.6149 82.682 13.9626C82.1244 13.3102 81.4332 12.7915 80.6116 12.4032C79.7884 12.0149 78.8472 11.8207 77.7879 11.8207C76.7286 11.8207 75.7859 12.0149 74.9642 12.4032C74.1411 12.7915 73.4514 13.3102 72.8939 13.9626C72.3363 14.6149 71.9107 15.3697 71.6187 16.2286C71.3252 17.0875 71.1791 17.9899 71.1791 18.9312C71.1791 19.8724 71.3252 20.7748 71.6187 21.6337C71.9107 22.4926 72.3363 23.2552 72.8939 23.9215C73.4514 24.5863 74.1411 25.1128 74.9642 25.5011C75.7859 25.8894 76.7286 26.0835 77.7879 26.0835C78.8472 26.0835 79.7884 25.8894 80.6116 25.5011C81.4332 25.1128 82.1244 24.5863 82.682 23.9215C83.2395 23.2567 83.6651 22.4941 83.9571 21.6337C84.2491 20.7748 84.3967 19.8739 84.3967 18.9312Z"
      fill="url(#paint0_linear_133_4586)"
    />
    <path
      d="M107.122 13.9595C106.704 13.3196 106.08 12.7853 105.246 12.3535C104.412 11.9233 103.522 11.7074 102.576 11.7074C101.548 11.7074 100.636 11.9015 99.8441 12.2914C99.052 12.6812 98.3779 13.2031 97.8219 13.8554C97.2658 14.5093 96.8418 15.2672 96.5498 16.1277C96.2578 16.9897 96.1118 17.8937 96.1118 18.838C96.1118 19.7823 96.2578 20.6878 96.5498 21.5483C96.8418 22.4103 97.2658 23.1682 97.8219 23.8206C98.3779 24.4744 99.0582 24.9948 99.8658 25.3846C100.672 25.7745 101.59 25.9686 102.618 25.9686C103.646 25.9686 104.53 25.7745 105.35 25.3846C106.17 24.9963 106.844 24.4667 107.372 23.8004L110 26.1783C109.166 27.1521 108.124 27.9085 106.872 28.4506C105.62 28.9926 104.204 29.2644 102.618 29.2644C101.032 29.2644 99.6779 29.0144 98.3857 28.5142C97.0934 28.0141 95.9736 27.3121 95.0277 26.4081C94.0818 25.5058 93.3379 24.4139 92.7958 23.1341C92.2537 21.8558 91.9819 20.4238 91.9819 18.838C91.9819 17.2522 92.246 15.8637 92.7741 14.5838C93.3021 13.3056 94.0383 12.2059 94.9842 11.2896C95.9285 10.3716 97.0484 9.66339 98.3422 9.16327C99.636 8.66315 101.032 8.41309 102.534 8.41309C103.924 8.41309 105.308 8.6911 106.684 9.24714C108.06 9.80318 109.151 10.6093 109.958 11.6654L107.122 13.9595Z"
      fill="url(#paint1_linear_133_4586)"
    />
    <path
      d="M85.4054 3.55034C84.4456 2.63241 83.3133 1.91795 82.0071 1.4023C80.6993 0.888198 79.2953 0.630371 77.7949 0.630371C76.2945 0.630371 74.8889 0.888198 73.5827 1.4023C72.2749 1.9164 71.1489 2.63241 70.2045 3.55034C70.0849 3.66682 69.9669 3.78642 69.8535 3.90912L72.5001 6.55573C72.6182 6.38798 72.744 6.2249 72.8791 6.06803C73.4367 5.4157 74.1263 4.89694 74.9495 4.50864C75.7711 4.12035 76.7139 3.9262 77.7731 3.9262C78.8324 3.9262 79.7736 4.12035 80.5968 4.50864C81.4184 4.89694 82.1096 5.4157 82.6672 6.06803C82.807 6.23267 82.939 6.40196 83.0632 6.58058L85.7471 3.89669C85.6353 3.77865 85.5219 3.66372 85.4039 3.55189L85.4054 3.55034Z"
      fill="url(#paint2_linear_133_4586)"
    />
    <path
      d="M59.224 0.557617C59.2209 4.76827 59.2193 5.60597 59.2177 9.52618V13.2585C58.7953 12.5937 58.3014 11.9911 57.7345 11.4475C56.7746 10.5311 55.6423 9.81507 54.3361 9.29942C53.0284 8.78532 51.6243 8.52904 50.1224 8.52904C48.6204 8.52904 47.2179 8.78532 45.9102 9.29942C44.6039 9.81507 43.4779 10.5311 42.5336 11.4475C41.5877 12.3654 40.8437 13.4573 40.3017 14.7215C39.7596 15.9874 39.4878 17.3837 39.4878 18.9136C39.4878 20.4434 39.7596 21.8475 40.3017 23.1258C40.8437 24.404 41.5877 25.5099 42.5336 26.4402C43.4779 27.3721 44.6039 28.0959 45.9102 28.61C47.2179 29.1241 48.622 29.3804 50.1224 29.3804C51.6227 29.3804 53.0284 29.1241 54.3361 28.61C55.6423 28.0959 56.7746 27.3721 57.7345 26.4402C58.3728 25.8205 58.918 25.1247 59.3715 24.3497C59.3653 25.8578 59.356 27.9887 59.3482 29.3089H63.1442L63.1442 0.557617H59.224ZM56.2729 21.6363C55.9794 22.4967 55.5554 23.2593 54.9978 23.9241C54.4402 24.5888 53.749 25.1154 52.9258 25.5037C52.1027 25.8935 51.1614 26.0861 50.1022 26.0861C49.0429 26.0861 48.1001 25.8935 47.2785 25.5037C46.4553 25.1154 45.7657 24.5888 45.2066 23.9241C44.649 23.2593 44.2234 22.4967 43.9314 21.6363C43.6379 20.7774 43.4919 19.8765 43.4919 18.9337C43.4919 17.991 43.6379 17.0901 43.9314 16.2312C44.2234 15.3723 44.649 14.6175 45.2066 13.9651C45.7657 13.3128 46.4553 12.7941 47.2785 12.4058C48.1001 12.0175 49.0414 11.8233 50.1022 11.8233C51.163 11.8233 52.1027 12.0175 52.9258 12.4058C53.749 12.7941 54.4402 13.3128 54.9978 13.9651C55.5554 14.6175 55.9794 15.3723 56.2729 16.2312C56.5665 17.0901 56.7125 17.991 56.7125 18.9337C56.7125 19.8765 56.5665 20.7774 56.2729 21.6363Z"
      fill="url(#paint3_linear_133_4586)"
    />
    <path
      d="M28.7158 29.4194V13.7211L35.869 9.40674V29.4194H28.7158Z"
      fill="#1E0F3C"
    />
    <path
      d="M35.2884 7.84486C34.9094 8.07628 34.3611 8.44439 33.1807 9.07032C33.1807 9.07032 32.603 9.37629 31.5965 9.7475C31.0777 9.93854 30.6196 10.0566 30.0651 9.95562C29.9408 9.93388 29.742 9.89505 29.5168 9.77235C29.1316 9.56112 28.9421 9.25204 28.8023 9.02527C28.689 8.84045 28.4529 8.44439 28.4311 7.8868C28.4234 7.67091 28.4482 7.49229 28.4731 7.37114C28.6082 7.4721 28.9701 7.7144 29.4391 7.67401C29.6178 7.65848 29.9284 7.63208 30.0853 7.38202C30.2266 7.15681 30.1536 6.89432 30.1334 6.82909C30.0682 6.739 30.0045 6.64426 29.9439 6.54486C29.4034 5.65955 29.2403 4.61426 29.4857 3.60315C29.7311 2.59203 30.3555 1.73778 31.2424 1.19728C31.8512 0.827624 32.5501 0.630371 33.2599 0.630371C34.633 0.630371 35.877 1.32775 36.59 2.49729C37.7051 4.33003 37.1211 6.72813 35.2884 7.84486Z"
      fill="#1E0F3C"
    />
    <defs>
      <linearGradient
        id="paint0_linear_133_4586"
        x1="77.9764"
        y1="30.2204"
        x2="71.6564"
        y2="8.59151"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF0F7B" />
        <stop offset="1" stop-color="#F89B29" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_133_4586"
        x1="101.118"
        y1="30.1053"
        x2="93.8716"
        y2="9.13728"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF0F7B" />
        <stop offset="1" stop-color="#F89B29" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_133_4586"
        x1="77.9119"
        y1="6.82054"
        x2="77.1724"
        y2="0.205145"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF0F7B" />
        <stop offset="1" stop-color="#F89B29" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_133_4586"
        x1="51.4821"
        y1="30.5428"
        x2="41.0572"
        y2="1.89033"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF0F7B" />
        <stop offset="1" stop-color="#F89B29" />
      </linearGradient>
    </defs>
  </svg>
);

export const WhiteLogo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="200"
    height="52"
    viewBox="0 0 200 52"
    fill="none"
  >
    <path
      d="M32.6311 51.9989V32.4471H13.0524V51.8287H2.07463e-06L0 0.144531L13.0524 0.144531L13.0524 19.6196H32.6311L32.6311 0.144531L45.6835 0.144531V51.9989H32.6311Z"
      fill="white"
    />
    <path
      d="M160.861 33.0477C160.861 35.8011 160.366 38.328 159.381 40.6286C158.395 42.9319 157.032 44.9194 155.287 46.5966C153.541 48.2738 151.483 49.5736 149.108 50.4988C146.731 51.4241 144.181 51.8881 141.45 51.8881C138.72 51.8881 136.167 51.4241 133.792 50.4988C131.415 49.5736 129.367 48.2738 127.651 46.5966C125.931 44.9194 124.578 42.9319 123.593 40.6286C122.607 38.328 122.113 35.8011 122.113 33.0477C122.113 30.2943 122.607 27.7813 123.593 25.5032C124.578 23.2278 125.931 21.2627 127.651 19.6107C129.367 17.9586 131.415 16.6728 133.792 15.7447C136.167 14.8195 138.72 14.3555 141.45 14.3555C144.181 14.3555 146.731 14.8195 149.108 15.7447C151.483 16.67 153.544 17.9586 155.287 19.6107C157.029 21.2627 158.395 23.225 159.381 25.5032C160.366 27.7813 160.861 30.2943 160.861 33.0477ZM153.431 33.084C153.431 31.3873 153.166 29.766 152.632 28.2202C152.099 26.6744 151.328 25.3159 150.314 24.1418C149.3 22.9678 148.044 22.0342 146.55 21.3354C145.053 20.6365 143.342 20.2871 141.416 20.2871C139.491 20.2871 137.777 20.6365 136.283 21.3354C134.786 22.0342 133.532 22.9678 132.519 24.1418C131.505 25.3159 130.731 26.6744 130.2 28.2202C129.667 29.766 129.401 31.3901 129.401 33.084C129.401 34.778 129.667 36.4021 130.2 37.9479C130.731 39.4937 131.505 40.8662 132.519 42.0654C133.532 43.2618 134.786 44.2094 136.283 44.9082C137.777 45.607 139.491 45.9564 141.416 45.9564C143.342 45.9564 145.053 45.607 146.55 44.9082C148.044 44.2094 149.3 43.2618 150.314 42.0654C151.328 40.869 152.101 39.4965 152.632 37.9479C153.163 36.4021 153.431 34.7808 153.431 33.084Z"
      fill="white"
    />
    <path
      d="M194.768 24.1246C194.008 22.973 192.873 22.0114 191.357 21.2343C189.841 20.46 188.223 20.0714 186.503 20.0714C184.634 20.0714 182.976 20.4208 181.536 21.1225C180.096 21.8241 178.87 22.7633 177.859 23.9373C176.849 25.1142 176.078 26.4783 175.547 28.0269C175.016 29.5783 174.751 31.2051 174.751 32.9047C174.751 34.6042 175.016 36.2339 175.547 37.7825C176.078 39.3339 176.849 40.698 177.859 41.8721C178.87 43.0489 180.107 43.9853 181.575 44.6869C183.041 45.3885 184.71 45.738 186.579 45.738C188.448 45.738 190.055 45.3885 191.546 44.6869C193.037 43.9881 194.263 43.0349 195.223 41.8357L200 46.1153C198.484 47.868 196.589 49.2293 194.313 50.2049C192.037 51.1804 189.462 51.6696 186.579 51.6696C183.696 51.6696 181.234 51.2196 178.884 50.3195C176.535 49.4194 174.499 48.1559 172.78 46.529C171.06 44.905 169.707 42.9399 168.722 40.6365C167.736 38.336 167.242 35.7587 167.242 32.9047C167.242 30.0507 167.722 27.5517 168.682 25.2483C169.642 22.9478 170.981 20.9687 172.7 19.3195C174.417 17.6675 176.453 16.3928 178.805 15.4927C181.158 14.5926 183.696 14.1426 186.427 14.1426C188.954 14.1426 191.47 14.6429 193.972 15.6437C196.474 16.6444 198.456 18.0951 199.924 19.996L194.768 24.1246Z"
      fill="white"
    />
    <path
      d="M155.282 5.39971C153.537 3.74768 151.478 2.46184 149.104 1.5338C146.726 0.608552 144.173 0.144531 141.446 0.144531C138.718 0.144531 136.162 0.608552 133.788 1.5338C131.41 2.45905 129.363 3.74768 127.646 5.39971C127.429 5.60936 127.214 5.8246 127.008 6.04543L131.819 10.8086C132.034 10.5067 132.263 10.2132 132.508 9.93091C133.522 8.75688 134.776 7.82324 136.272 7.12442C137.766 6.42559 139.48 6.07618 141.406 6.07618C143.332 6.07618 145.043 6.42559 146.54 7.12442C148.033 7.82324 149.29 8.75688 150.304 9.93091C150.558 10.2272 150.798 10.5319 151.024 10.8534L155.903 6.02307C155.7 5.81062 155.494 5.60377 155.279 5.40251L155.282 5.39971Z"
      fill="white"
    />
    <path
      d="M107.657 0C107.651 7.57808 107.648 9.08573 107.645 16.1411V22.8582C106.877 21.6618 105.979 20.5772 104.949 19.5989C103.204 17.9496 101.145 16.661 98.7703 15.733C96.3927 14.8077 93.8401 14.3465 91.1095 14.3465C88.379 14.3465 85.8291 14.8077 83.4515 15.733C81.0768 16.661 79.0296 17.9496 77.3127 19.5989C75.5931 21.2509 74.2405 23.216 73.255 25.4914C72.2695 27.7696 71.7754 30.2825 71.7754 33.0359C71.7754 35.7893 72.2695 38.3163 73.255 40.6168C74.2405 42.9173 75.5931 44.9076 77.3127 46.582C79.0296 48.2592 81.0768 49.5618 83.4515 50.487C85.8291 51.4123 88.3818 51.8735 91.1095 51.8735C93.8372 51.8735 96.3927 51.4123 98.7703 50.487C101.145 49.5618 103.204 48.2592 104.949 46.582C106.109 45.4667 107.1 44.2144 107.925 42.8195C107.914 45.5337 107.897 49.3689 107.883 51.7449H114.784L114.784 0H107.657ZM102.292 37.9361C101.758 39.4847 100.987 40.8572 99.9732 42.0536C98.9595 43.25 97.7029 44.1976 96.2064 44.8964C94.7098 45.598 92.9986 45.9446 91.0728 45.9446C89.147 45.9446 87.433 45.598 85.9393 44.8964C84.4427 44.1976 83.1889 43.25 82.1724 42.0536C81.1587 40.8572 80.385 39.4847 79.8541 37.9361C79.3204 36.3903 79.055 34.769 79.055 33.0723C79.055 31.3755 79.3204 29.7542 79.8541 28.2084C80.385 26.6626 81.1587 25.3041 82.1724 24.1301C83.1889 22.956 84.4427 22.0224 85.9393 21.3236C87.433 20.6248 89.1442 20.2753 91.0728 20.2753C93.0014 20.2753 94.7098 20.6248 96.2064 21.3236C97.7029 22.0224 98.9595 22.956 99.9732 24.1301C100.987 25.3041 101.758 26.6626 102.292 28.2084C102.825 29.7542 103.091 31.3755 103.091 33.0723C103.091 34.769 102.825 36.3903 102.292 37.9361Z"
      fill="white"
    />
    <path
      d="M52.2324 51.9287V23.6758L65.2371 15.9111V51.9287H52.2324Z"
      fill="white"
    />
    <path
      d="M64.1296 13.1287C63.4406 13.5452 62.4438 14.2077 60.2978 15.3342C60.2978 15.3342 59.2473 15.8849 57.4176 16.553C56.4744 16.8968 55.6414 17.1093 54.6334 16.9276C54.4075 16.8884 54.046 16.8185 53.6366 16.5977C52.9363 16.2176 52.5918 15.6613 52.3377 15.2532C52.1315 14.9205 51.7023 14.2077 51.6628 13.2042C51.6487 12.8157 51.6938 12.4942 51.739 12.2762C51.9847 12.4579 52.6426 12.8939 53.4954 12.8213C53.8201 12.7933 54.3849 12.7458 54.6701 12.2957C54.927 11.8904 54.7943 11.418 54.7576 11.3006C54.639 11.1385 54.5232 10.968 54.4131 10.7891C53.4304 9.19574 53.134 7.3145 53.5801 5.49475C54.0263 3.67501 55.1614 2.13759 56.7738 1.16482C57.8807 0.499535 59.1513 0.144531 60.4418 0.144531C62.938 0.144531 65.1998 1.39962 66.4959 3.50449C68.5233 6.80296 67.4616 11.1189 64.1296 13.1287Z"
      fill="white"
    />
  </svg>
);
