import { useTranslation } from "react-i18next";
import "./contact.css";
import NewLayout from "../layout/newLayout";
import ContactForm from "./contactForm";

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <NewLayout>
      <div className="contact-page">
        <div className="container">
          <div className="contact-title">
            <h2>{t("contact.heading")}</h2>
            <p>{t("contact.paragraph1")}</p>
            <p>{t("contact.paragraph2")}</p>
            <p>
              {t("contact.paragraph3")}{" "}
              <a
                href="mailto:support@hidoc.co.il"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@hidoc.co.il
              </a>
            </p>
          </div>
          <ContactForm />
        </div>
      </div>
    </NewLayout>
  );
};

export default ContactUs;
