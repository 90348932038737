import Slider from "react-slick";
import { Button } from "react-bootstrap";
import { StarIcon } from "../common/icon";
import avtar1 from "../../assets/images/new/doctor1.png";

const DoctorTestimonial = () => {
  const settings = {
    dots: true,
    cssEase: "linear",
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    centerPadding: "50px",
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          autoplay: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          autoplay: true,
        },
      },
    ],
  };
  return (
    <div className="doctor-testimonial-section">
      <div className="container">
        <div className="doctor-testimonial-title">
          <h2>הם יודעים מה הם אומרים</h2>
        </div>
        <div className="new-expert-slider">
          <Slider {...settings}>
            <TestimonialBox />
            <TestimonialBox />
            <TestimonialBox />
            <TestimonialBox />
            <TestimonialBox />
          </Slider>
        </div>
        <div className="feature-cta">
          <Button className="black-btn">בוא נבחר את הרופא שלך</Button>
        </div>
      </div>
    </div>
  );
};

export default DoctorTestimonial;

const TestimonialBox = () => {
  return (
    <div className="testimonial-box doctor-testimonial">
      <div className="star-grid">
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
      </div>
      <div className="testimonial-text">
        <h6>
          ״קדם רפואה. במאה משפטית מתבססת עיניים לדור את אך הסניטציה, האחרונות על
          או בפצעים בימי לעסוק פרה שהידע וגרון מסוימות. הרפואה במחלות השנים כגון
          גידול גם להם .״
        </h6>
      </div>
      <div className="testimonial-user-info">
        <div className="testimonial-user-left">
          <img src={avtar1} alt="avtar" />
        </div>
        <div className="testimonial-user-right">
          <h6>לקוח מרוצה</h6>
          <p>תחום מענה</p>
        </div>
      </div>
    </div>
  );
};
