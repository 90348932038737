import { Button } from "react-bootstrap";
import FaqList from "./faqList";

const FaqSection = () => {
  const scrollToContact = () => {
    let contact = document.getElementById("contact-section");
    if (contact) {
      contact.scrollIntoView({ top: 0, behavior: "smooth" });
    } else {
      window.location.href = "/#contact-section";
    }
  };
  return (
    <div className="faq-section">
      <div className="container">
        <div className="new-faq-title">
          <h2>שאלות תשובות</h2>
          <p>הנה תשובות לשאלות נפוצות</p>
        </div>
        <FaqList />
        <div className="still-que-grid">
          <h3>שאלות?</h3>
          <p>צרו איתנו קשר ונשמח לענות על הכל</p>
          <Button className="border-btn" onClick={scrollToContact}>
            צור קשר
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
