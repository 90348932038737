import ichilovBanner from "../../assets/images/new/partner/ichilov-banner.svg";
import "./partner.css";

const IchilovBanner = () => {
  return (
    <div className="partner-banner-section">
      <div className="container">
        <div className="partner-banner-grid">
          <img src={ichilovBanner} alt="banner" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default IchilovBanner;
