import doctorBox from "../../assets/images/new/doctor-box.svg";

const DoctorBanner = () => {
  return (
    <div className="doctor-banner">
      <div className="container">
        <div className="doctor-banner-grid">
          <div className="doctor-banner-left">
            <img src={doctorBox} alt="icon" />
          </div>
          <div className="doctor-banner-right">
            <h1>בריאות האישה</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorBanner;
