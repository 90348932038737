import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "./consulting.css";
import NewLayout from "../layout/newLayout";
import ConsultingBanner from "./banner";
import ConsultingDetailSection from "./consultingDetailSection";
import FaqSection from "../newHome/faqSection";
// import BlogsSection from "./blogsSection";
// import ExclusionSection from "./exclusionsSection";
import AllCategoryData from "../../components/consulting/category.json";

const ConsultingDetail = () => {
  let [searchParams] = useSearchParams();
  const searchKey = searchParams.get("searchKey");
  const [categoriesData, setcategoriesData] = useState(AllCategoryData);

  const getAllConsultingFields = async () => {
    setcategoriesData(AllCategoryData);
  };

  const singleData = categoriesData.filter((value) => {
    return value?.title?.toLowerCase()?.includes(searchKey?.toLowerCase());
  });

  useEffect(() => {
    getAllConsultingFields();
  }, []);
  return (
    <NewLayout>
      <div className="consulting-page">
        <ConsultingBanner singleConsulting={singleData[0]} />
        <ConsultingDetailSection singleConsulting={singleData[0]} />
        {/* <ExclusionSection /> */}
        <FaqSection />
        {/* <BlogsSection /> */}
      </div>
    </NewLayout>
  );
};

export default ConsultingDetail;
