import { Button } from "react-bootstrap";
import avtar1 from "../../assets/images/new/doctor1.png";
import avtar2 from "../../assets/images/new/doctor2.png";
import avtar3 from "../../assets/images/new/doctor3.png";

const RelatedDoctor = () => {
  return (
    <div className="related-doctor-section">
      <div className="container">
        <div className="related-doctor-title">
          <h2>רופאים נוספים בתחום</h2>
        </div>
        <div className="related-doctor-grid">
          <div className="related-doctor-box">
            <div className="related-doctor-avtar">
              <img src={avtar1} alt="avtar" />
            </div>
            <div className="doctor-spailciality">
              <Button className="pink-btn">כירורג</Button>
              <Button className="pink-btn">גניקולוג</Button>
            </div>
            <div className="related-doctor-detail">
              <h5>ישראל ישראלי</h5>
              <h6>מומחה רפואת נשים</h6>
              <p>בזמינות גבוהה ובמחיר נגיש ותהנה מייעוץ </p>
            </div>
          </div>
          <div className="related-doctor-box">
            <div className="related-doctor-avtar">
              <img src={avtar2} alt="avtar" />
            </div>
            <div className="doctor-spailciality">
              <Button className="pink-btn">כירורג</Button>
              <Button className="pink-btn">גניקולוג</Button>
            </div>
            <div className="related-doctor-detail">
              <h5>ישראל ישראלי</h5>
              <h6>מומחה רפואת נשים</h6>
              <p>בזמינות גבוהה ובמחיר נגיש ותהנה מייעוץ </p>
            </div>
          </div>
          <div className="related-doctor-box">
            <div className="related-doctor-avtar">
              <img src={avtar3} alt="avtar" />
            </div>
            <div className="doctor-spailciality">
              <Button className="pink-btn">כירורג</Button>
              <Button className="pink-btn">גניקולוג</Button>
            </div>
            <div className="related-doctor-detail">
              <h5>ישראל ישראלי</h5>
              <h6>מומחה רפואת נשים</h6>
              <p>בזמינות גבוהה ובמחיר נגיש ותהנה מייעוץ </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedDoctor;
