import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import "./common.css";
import Storage from "../../utils/storage";

const CookieModal = ({
  showCookie,
  setShowCookie,
  handleConfirmCookie,
  handleCancelCookie,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={showCookie}
      onHide={() => {
        setShowCookie(false);
      }}
      className={`cookie-modal ${Storage.get("lang")?.isRTL ? "rtl" : "ltr"}`}
      size="lg"
      backdrop="static"
    >
      <Modal.Body>
        <div className="cookie-content">
          <h6>
            {t(
              "This site uses cookies to improve your user experience. By continuing to browse the site, you agree to our use of cookies."
            )}
          </h6>
          <h6>
            {t(
              "Privacy Policy: For more information about how we use your personal information and about your rights, please review our privacy policy."
            )}
          </h6>
          <h6>
            {t(
              "Confirmation: By clicking Confirm, you agree to our use of cookies in the manner described in our privacy policy."
            )}
          </h6>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="border-btn" onClick={handleCancelCookie}>
          {t("does not approve")}
        </Button>
        <Button className="border-btn" onClick={handleConfirmCookie}>
          {t("confirms")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CookieModal;
