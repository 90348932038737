import { useState, useRef, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./supportChat.css";
import supportIcon from "../../assets/images/icons/support-icon.svg";
import whatsappIcon from "../../assets/images/icons/whatsapp-icon.png";
import callIcon from "../../assets/images/icons/call-icon.png";
import envelopIcon from "../../assets/images/icons/envelop-icon.png";

const SupportChat = () => {
  const wrapperRef = useRef(null);

  const [showChat, setShowChat] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowChat(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(wrapperRef);

  const handleChat = () => {
    setShowChat(!showChat);
  };
  const handleAlert = () => {
    setShowAlert(!showAlert);
  };

  return (
    <div ref={wrapperRef}>
      <div className="support-chat floating">
        <Button onClick={handleChat}>
          {" "}
          <img src={supportIcon} alt="icon" />{" "}
        </Button>
      </div>
      {showChat ? (
        <div className="support-modal">
          <div className="support-title">
            <h6>נשמח לשוחח איתך</h6>
            <p>אנחנו נשמח לעמוד לרשותך ולעזור לך </p>
          </div>
          <div className="support-grid">
            <a
              href="https://api.whatsapp.com/send?phone=972526001369"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={whatsappIcon} alt="icon" />
            </a>
            <span onClick={handleAlert} className="desktop-view">
              <img src={callIcon} alt="icon" />
            </span>

            <a
              href="tel:0732072226"
              rel="noopener noreferrer"
              className="mobile-view"
            >
              <img src={callIcon} alt="icon" />
            </a>
            <Link to="/contact" rel="noopener noreferrer">
              <img src={envelopIcon} alt="icon" />
            </Link>
          </div>
        </div>
      ) : null}
      {showAlert ? (
        <AlertModal showAlert={showAlert} setShowAlert={setShowAlert} />
      ) : null}
    </div>
  );
};

export default SupportChat;

const AlertModal = ({ showAlert, setShowAlert }) => {
  return (
    <Modal
      onHide={() => setShowAlert(false)}
      show={showAlert}
      centered
      className="alert-modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="alert-modal-grid">
          <a href="tel:0732072226">073-2072226</a>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};
